.avatarVendedorImgBckground {
    position: relative;
    left: -24px !important;
    width: 520px !important;
    top: -10px !important;
    height: 70px !important;
    background-color: transparent;
    text-align: center;
    display: list-item;
}

.avatarVendedorImg {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border-style: solid;
    border-color: #FFFFFF;
    box-shadow: 0 0 8px 3px #B8B8B8;
    position: relative;
    object-fit: cover;
    cursor: pointer;
}

.avatarVendedorIcon {
    cursor: pointer;
    position: relative;
    top: -60px;
    left: 285px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: cornflowerblue;
    box-shadow: 0 0 8px 3px #B8B8B8;
}