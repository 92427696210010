/* Currency imputy border */
.wrapInputComissao:empty {
    height: 30px;
    width: 150px;
    border: solid 1px rgb(209, 206, 206) !important;
    border-radius: 3px !important;
}

.wrapInputComissao:hover {
    border: solid 1px #1890ff !important;
}

.paragrafoNotaComissao {
    color: red;
    line-height: 1.3;

}