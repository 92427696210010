.fontePoliticaPrivacidade {
    font-family: 'Helvetica', 'Arial', sans-serif;
    color: #444444;
    font-size: 12pt;
    background-color: #FAFAFA;
}

.fontePoliticaPrivacidade .titulo {
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
}

.fontePoliticaPrivacidade .subTitulo {
    text-align: left;
    font-weight: bold;
    margin-top: 10px;
}

.fontePoliticaPrivacidade p {
    margin-left: 15px;
    margin-right: 15px;
}

.fontePoliticaPrivacidade .recuoEsquerdo {
    margin-left: 35px;
    margin-right: 15px;
}