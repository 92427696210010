.accountForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginInput {
  height: 5rem !important;
  width: 30rem !important;
}

.loginInput #password.ant-input,
.loginInput #username.ant-input {
  padding-left: 3rem !important;
}

.iconLoginLock {
  color: rgb(0, 0, 0, .25) !important;
}

.recaptcha {
  margin-bottom: 2rem;
}

.formButton {
  height: 5rem !important;
  width: 150px !important;
  position: relative;
  top: -35px;
}

.esqueciSenha {
  text-decoration: underline !important;
  cursor: pointer;
  position: relative;
  top: -55px;
}
