.logo {
  float: left;
}

.center {
  margin: auto;
  color: white;
  justify-content: center;
  font-family: "Trocchi", serif;
  font-size: 2.5vw;
  margin-left: 5px;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bluvelayout-menu {
  font-weight: 400;
  font-size: 1.6rem;
  background-color: transparent;
  margin: auto;
}

.logoLayoutMaster {
  position: relative;
  width: 9.50rem;
  height: 44px;
  top: -5px;
  margin: 1rem;
  content: url("https://storage.googleapis.com/bluve-bucket/logo_oficial_branca_com_azul-min.png");
}

.labelLogoShow {
  position: relative;
  top: 5px;
  font-size: 3vw;
}

table {
  margin-bottom: 50px;
}

.menuAjuda {
  font-size: 1.6rem;
}

a:hover .menuAjuda {
  color: #FFF;
  transition: color 0.3s;
}

.profileAvatar {
  position: relative;
  top: -20px;
  height: 30px !important;
  width: 30px !important;

  margin: 2.8rem 2rem;
  margin-left: auto;
  border: 0.2rem solid #D2D2D2;
  cursor: pointer;
  border-radius: 50%;
  border-style: solid;
  border-color: #FFFFFF;
  box-shadow: 0 0 8px 3px #B8B8B8;
  position: relative;
  object-fit: cover;
}

.card-profile {
  position: relative;
  top: 0px;
  left: -5px;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.2);
  margin: auto;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  width: 250px;
  background-color: #e6e6e6;
}

.card-profile>label {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0;
  margin-right: 0.313rem;
  text-align: end;
}

.card-profile h3 {
  position: relative;
  font-size: 24px;
  top: 15px;
}

.img-profile {
  top: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-style: solid;
  border-color: #FFFFFF;
  box-shadow: 0 0 8px 3px #B8B8B8;
  position: relative;
  object-fit: cover;
}

.title-profile {
  color: rgb(87, 86, 86);
  font-size: 1.125rem;
  position: relative;
  top: 5px;
}

.buttonProfile {
  border: none;
  outline: 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 80%;
}

.buttonProfileExit {
  border: none;
  outline: 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  width: 80%;
  top: -1rem;
}

.card-profile>label {
  font-size: 1.2rem;
  display: block;
  margin-bottom: 0;
  margin-right: 0.5rem;
  text-align: end;
}

.a-profile {
  text-decoration: none;
  font-size: 2.2rem;
  color: black;
}

.buttonProfile:hover,
.a-profile:hover {
  opacity: 0.7;
}

.iconFont {
  top: 01px !important;
}

.ant-form-item-label>label {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.85);
}

.ant-input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]),
select,
textarea {
  border: solid 0.1rem rgb(209, 206, 206) !important;
  border-radius: 0.3rem !important;
  display: block;
  width: 100%;
  outline: none !important;
  color: #262626;
  background-color: transparent;
  line-height: 3.4rem;
  resize: none;
  margin-bottom: 0 !important;
  padding: 0 1rem !important;
}

.ant-input:not([type="submit"]):not([type="radio"]):not([type="checkbox"]):hover {
  border: solid 0.1rem #1890ff !important;
}

select:hover {
  border: solid 0.1rem #1890ff !important;
}

textarea:hover {
  border: solid 0.1rem #1890ff !important;
}

input:not([type="range"]) {
  border: none !important;
  width: 100% !important;
  color: rgb(87, 84, 84) !important;
}

.ant-select-selection--single {
  position: relative;
  height: 3.2rem;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.rangePickerDuplo,
.rangePickerCustom {
  display: inline-block;
  position: relative;
  max-width: 30rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.rangePickerDuplo .ant-calendar-range-picker-input {
  width: 48% !important;
  display: inline-block !important;
}

.rangePickerCustom .ant-calendar-picker {
  width: 45% !important;
  display: inline-block !important;
}


/* Layout interno. Sem essa regulagem, ele fica por baixo do rodapé  */

.ant-layout {
  /*height: 92.5vh !important; retirado por não usar mais rodapé*/
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.bluveLogoTopo {
  position: relative;
  top: -0.7rem;
  left: -2rem !important;
  /* height: 88px; */
  width: 14rem;
  margin-left: 2rem;
}

.nav-blue li>a {
  color: darkblue !important;
  font-size: 1.8rem;
}

.banner-back {
  background-image: url(../../assets/template/images/ice.jpg);

}

.alertaCompra {
  background-color: #fff174;
  border-radius: 25px;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  padding-left: 20px;
}

.alertaDeNovidade {
  display: flex;
  align-items: center;
  height: 4rem;

  background-color: #167ad8;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
}

.textoAlerta {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}

.botaoAlerta {
  margin: 1rem;
  margin-left: auto;
}

.bluvelayout-help-button {
  left: -120px;
  top: 10px;
  border: none;
  background-color: transparent;
  color: #9da3af;
  font-size: 17px;  
}

.bluvelayout-help-button:hover {
  background-color: transparent;
}
