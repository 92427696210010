.containerPagamentos {
    position: fixed !important;
    margin-top: 50px !important;
    width: 96%;
}

.lblTotalAPagarDescricao {
    margin-left: 10px;
    font-size: 18pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.lblTotalPagamentoIndicador {
    top: 10px;
    color: green;
    margin-left: 10px;
    font-size: 18pt;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}