body {
  --dark-color: #143361;
  --bright-color: #1890ff;
  --step-container-color: #F0F2F5;
}

.componentContainer {
  height: 100vh;

  font-family: 'Ubuntu', sans-serif;
  font-style: normal;

  color: var(--dark-color);
}


/* Header */
.header {
  display: flex;
  align-items: center;

  height: 10rem;

  background-color: var(--dark-color);
}

.logo {
  float: left;
}

.logoLayoutMasterPrimeiroAcesso {
  margin: 1rem;

  width: 15.6rem;
  height: 8rem;

  content: url('https://storage.googleapis.com/bluve-bucket/logo_oficial_branca_com_azul-min.png');
}


/* Body */
.body {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 85vh;

  background-color: white;
}

.stepContainer {
  display: flex;
  flex-direction: column;

  width: 40%;
  height: fit-content;
  padding: 5rem;

  background-color: var(--step-container-color);
  box-shadow: 6px 8px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
}

.textContainer {
  margin-bottom: 2rem;
}

.title {
  font-weight: bold;
  font-size: 2.6rem;

  color: var(--dark-color);
}

.description {
  font-weight: normal;
  font-size: 1.8rem;

  margin: 1rem 0;
}

.bold {
  margin-top: 2rem;

  font-weight: bold;
  color: var(--bright-color);
}

.boldInLine {
  font-weight: bold;
  color: var(--bright-color);
}

input::placeholder {
  font-weight: 400;
  opacity: 70%;
}

.stepInput:not([type='range']),
.stepInput:not([type='submit']):not([type='radio']):not([type='checkbox']) {
  border: 0 !important;
  border-bottom: 0.2rem solid var(--bright-color) !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  box-shadow: none;

  font-size: 1.8rem;

  color: var(--dark-color) !important;
}

.stepInput:not([type='submit']):not([type='radio']):not([type='checkbox']):hover {
  border: 0 !important;
  border-bottom: 0.2rem solid var(--bright-color) !important;
  border-radius: 0 !important;
}

.stepInput:not([type='submit']):not([type='radio']):not([type='checkbox']):focus::placeholder {
  opacity: 0%;
}

.buttonConfirm {
  margin: 5rem auto 0;

  width: 18rem;
  height: 4rem;

  font-size: 1.8rem;
  border-radius: 1rem;
}

/* Footer */
.footer {
  display: flex;
  justify-content: center;

  color: var(--dark-color);
  font-size: 1.8rem;
  font-weight: bold;
}


/* Outros */

.enderecosContainer,
.tiposContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: fit-content;
  row-gap: 1rem;
}

.selectEndereco,
.selectTipo {
  display: flex;
  justify-content: center;

  min-width: 100%;
  padding: 0.5rem;

  font-weight: 500;
  font-size: 2rem;

  color: var(--dark-color);
  opacity: 65%;

  border: 0.2rem solid var(--dark-color);
  border-radius: 1rem;
}

.selectEndereco:hover,
.selectTipo:hover,
.selected {
  background-color: var(--bright-color);
  border-color: var(--bright-color);

  color: white;
  opacity: 100%;

  cursor: pointer;
  transition: 300ms;
}

.passStrength>div {
  height: 1rem;
}

.passStrength>p {
  height: 2.2rem;
  line-height: 2.2rem;
}




/* Retirar isso quando arrumar o css do site */
.botaoSelecionar {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 100%;
  height: 4rem;

  font-weight: 500;
  font-size: 2rem;

  border-radius: 1rem;
}
