/* Escala de vendedores */

.divEscalaMetaButtons button {
  margin-top: 15px;
  margin-left: 10px;
}

.divSideBySideMeta {
  display: flex;
}

.divSideBySideMeta .wrapDiasTrabahados {
  margin-left: 5px;
  margin-right: 5px;
}

.divStepCurrent1,
.divMeta {
  position: relative;
  height: auto;
  margin-top: 20px;
}

.btnAnteriorStepMeta {
  margin-right: 5px;
}

/* Metas */

.divMeta .ant-form-item {
  margin-bottom: 0px;
}

.btnCopiarMesAnterior {
  text-align: center;
  margin-bottom: 20px;
}

.disabledElement {
  background-color: #dddddd !important;
}

/* Currency imputy border */
.wrapValorMeta:empty {
  height: 30px;
  width: 150px;
  border: solid 1px rgb(209, 206, 206) !important;
  border-radius: 3px !important;
}

.wrapValorMeta:hover {
  border: solid 1px #1890ff !important;
}

.divSideBySideMeta {
  display: flex;
}

.divSideBySideMeta .wrapValorMeta {
  margin-left: 5px;
  margin-right: 5px;
}

.wrapPercentualMeta {
  margin-left: 5px;
  margin-right: 75px;
}

.divSideBySideMeta label {
  position: absolute;
  left: 210px;
}

.wrapPercentualComissaoVendedorMeta {
  display: flex;
  width: 100px !important;
  position: relative;
  left: 6px;
}

.wrapPercentualComissaoVendedorMeta label {
  margin-left: 10px;
}

.textWarningPesoMeta {
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  position: relative;
  left: 100px;
  width: fit-content;
}

.tableResultadoMetas {
  margin-top: 20px;
}
